import { BaseSchemes, NodeId } from "rete";
import { AreaPlugin } from "rete-area-plugin";
import * as decomp from "poly-decomp-es";
import intersects from "intersects";

type Position = { x: number, y: number }

function screenToEditorCoordinates(point: Position, position: Position, zoom: number): {
    x: number;
    y: number;
} {
    return {
        x: (point.x - position.x) / zoom,
        y: (point.y - position.y) / zoom
    }
}

function getPoint(event: PointerEvent, container: HTMLElement): {
    x: number;
    y: number;
} {
    const rect = container.getBoundingClientRect();

    return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
    }
}

export type Mode = "rect" | "center"
export type Shape = "lasso" | "marquee"

type Options = {
    selected: (event: PointerEvent, ids: NodeId[]) => void
    button?: number
    mode?: Mode
    shape?: Shape
}

export function setupSelection<S extends BaseSchemes, K>(area: AreaPlugin<S, K>, options?: Options): {
    setMode(mode: Mode): void;
    setShape(shape: Shape): void;
    setButton(button: 0 | 1): void;
    destroy: () => void;
} {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const lasso = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
    const { container } = area
    const selected = options?.selected ?? ((): null => null)
    let currentButton = options?.button ?? 1
    let currentMode = options?.mode ?? "rect"
    let currentShape = options?.shape ?? "lasso"

    svg.appendChild(lasso);
    svg.setAttribute("id", "lasso")
    container.appendChild(svg)

    let _points: { x: number, y: number }[] = [];
    let isActive = false;

    container.addEventListener("pointerdown", start);
    container.addEventListener("pointermove", move);
    container.addEventListener("pointerup", up);

    function start(event: PointerEvent): void {
        if (event.button !== currentButton) return
        isActive = true;

        _points = [getPoint(event, container)];
        updatePolygon();
    }

    function move(event: PointerEvent): void {
        if (!isActive) return;

        if (currentShape === "lasso") {
            _points.push(getPoint(event, container));
        } else {
            const first = _points[0]
            const current = getPoint(event, container)

            _points = [first, { x: first.x, y: current.y }, current, { x: current.x, y: first.y }]
        }
        updatePolygon();
    }

    function intersectNodes(points: Position[]): {
        id: string;
        x: number;
        y: number;
        width: number;
        height: number;
    }[] {
        const { k } = area.area.transform
        const decompPoints: decomp.Polygon = points.map(point => [point.x, point.y])

        decomp.makeCCW(decompPoints);

        const polygons = (decomp.quickDecomp(decompPoints) as [number, number][][])
            .map(polygon => polygon.flat())

        const nodes = Array.from(area.nodeViews.entries()).map(([id, view]) => {
            const rect = view.element.getBoundingClientRect()
            const { x, y } = view.position
            const width = rect.width / k
            const height = rect.height / k

            return { id, x, y, width, height }
        })

        const selectedNodes = nodes.filter(({ x, y, width, height }) => {
            return polygons.some(ps => currentMode === "rect"
                ? intersects.polygonBox(ps, x, y, width, height)
                : intersects.polygonCircle(ps, x + width / 2, y + height / 2, 10))
        })

        return selectedNodes
    }

    function up(event: PointerEvent): void {
        const { x, y, k } = area.area.transform
        const editorPoints = _points.map(point => screenToEditorCoordinates(point, { x, y }, k))

        if (editorPoints.length >= 3) {
            const nodes = intersectNodes(editorPoints)

            selected(event, nodes.map(({ id }) => id))
        }

        isActive = false;
        _points = []
        updatePolygon();
    }

    function updatePolygon(): void {
        const pointString = _points.map(function (point) {
            return point.x + "," + point.y;
        }).join(" ");
        lasso.setAttribute("points", pointString);
    }

    return {
        setMode(mode: Mode): void {
            currentMode = mode
        },
        setShape(shape: Shape): void {
            currentShape = shape
        },
        setButton(button: 0 | 1): void {
            currentButton = button
        },
        destroy: (): void => {
            container.removeEventListener("pointerdown", start);
            container.removeEventListener("pointermove", move);
            container.removeEventListener("pointerup", up);
        }
    }
}
