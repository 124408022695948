import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild, computed, inject } from "@angular/core";
import { ConnectionDrop, ReteService } from "../core/services/rete.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { GraphInfoModel, GraphModel, NodeModel } from "../schemas/model";
import { RegistryService } from "../core/services/registry.service";
import { classicConnectionPath, GraphService } from "../core/services/graph.service";
import { featherShare2 } from "@ng-icons/feather-icons";
import { generateRandomWord } from "../../app/core/helper/wordlist";
import { IGraph, IInput, INode, IOutput, Permission } from "../schemas/graph";
import { BaseNode } from "../core/helper/rete/basenode";
import { environment } from "src/environments/environment";
import { GatewayService } from "../core/services/gateway.service";
import { dump, load, YAMLException } from "js-yaml";
import { NotificationService, NotificationType } from "../core/services/notification.service";
import { autoFit, createUniqueNodeId, ctrlKey as _ctrlKey, getErrorMessage, getOverridenPermission, GRAPH_TYPE_GENERIC, GRAPH_TYPE_GROUP, GRAPH_VERSION, htmlIsUserInputField, isDocs, isEmbedded, modifierKey as _modifierKey, NoThrowPromise, openDocs, showBackground, showHeader, showToolbar } from "../core/helper/utils";
import { WriteInfo, FilesystemService } from "../core/services/filesystem.service";
import { simpleGithub } from "@ng-icons/simple-icons";
import { TourService } from "ngx-ui-tour-md-menu";
import { Subscription } from "rxjs";
import { AngularDeviceInformationService } from "angular-device-information";
import { LoggerService, LogLevel } from "../core/services/logger.service";
import { tablerClipboardSmile, tablerFile, tablerFolderOpen } from "@ng-icons/tabler-icons";
import { ErrorService } from "../core/services/error.service";
import { ACTERROR, ActError } from "../core/helper/errors";
import { ionSave, ionSaveSharp } from "@ng-icons/ionicons";
import { PopStateEvent } from "@angular/common";
import { ShortcutService } from "../core/services/shortcut.service";
import { DoubleClickHandler } from "../core/helper/doubleclick";
import { Position } from "rete-angular-plugin/types";
import { BaseConnection } from "../core/helper/rete/baseconnection";
import { connectionId } from "../schemas/convert";

import confetti from "canvas-confetti";
import tippy from "tippy.js";
import Showdown from "showdown";

enum Shortcut {
  SwitchSelectionTool = "B",
  FitToWindow = "E",
  Arrange = "G"
}

enum ActionMode {
  Marquee = 1,
  Lasso = 2
}

interface Command {
  icon: string,
  tourAnchor: string | null;
  tooltip: string;
  tooltipBottom: boolean;
  class: string;
  click: (event: MouseEvent) => void;
}

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})
export class EditorComponent implements AfterViewInit, OnInit {
  @ViewChild("rete") rete!: ElementRef<HTMLElement>;
  @ViewChild("fileInput") fileInput!: ElementRef<HTMLElement>;
  @ViewChild("tab1") tab1!: ElementRef<HTMLAudioElement>;
  @ViewChild("tab2") tab2!: ElementRef<HTMLAudioElement>;

  LogLevel = LogLevel
  Permission = Permission;
  ActionMode = ActionMode;

  rs = inject(ReteService);
  cdr = inject(ChangeDetectorRef);
  clipboard = inject(Clipboard);
  registry = inject(RegistryService);
  gs = inject(GraphService);
  gw = inject(GatewayService);
  es = inject(ErrorService);
  ns = inject(NotificationService);
  fs = inject(FilesystemService);
  logger = inject(LoggerService);
  scs = inject(ShortcutService);
  tourService = inject(TourService);
  deviceInformationService = inject(AngularDeviceInformationService);

  isEmbedded = isEmbedded();
  environment = environment;

  mode = ActionMode.Marquee;
  loggerHasErrors = computed(() => this.logger.errors() > 0);

  version = this.deviceInformationService.isDesktop() && !isDocs() && !isEmbedded() ? `Last editor update: ${environment.updatedAt}` : null;

  public showToolbar = showToolbar();
  public showBackground = showBackground();
  public showHeader = showHeader();

  public expandNodeLibrary = true;
  public expandConsole = false;

  public labelSwitchSelectionTool = "";
  public labelFitToWindow = "";
  public labelArrange = "";
  public labelSave = "";
  public labelSaveAs = "";

  private _isMac = false;
  private _untitledCnt = 0;
  private _autoFitting = false;
  private _nodePlacementOffset = 0;

  private _isCtrlOrMetaPressed = false;

  getGraphInfo(): GraphInfoModel & { label?: string } | null {
    const current = this.gs.getCurrentGraph();
    if (!isDocs() && current && current.nodes.size === 1) {
      const firstNode: NodeModel | undefined = current.nodes.values().next().value;
      if (firstNode) {
        return { label: firstNode.label, ...firstNode.graph };
      } else {
        return null;
      }
    }
    return null;
  }

  @HostListener(`window:keydown.control.${Shortcut.SwitchSelectionTool}`, ["$event"])
  setActionMode(event: MouseEvent | KeyboardEvent, mode?: ActionMode): void {
    // cut only if the focus is not on an input or textarea
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    if (event instanceof KeyboardEvent) {
      mode = mode === ActionMode.Lasso ? ActionMode.Marquee : ActionMode.Lasso;
    }

    if (mode !== undefined) {
      this.mode = mode;
      if (mode === ActionMode.Marquee) {
        this.rs.setSelectionToolShape("marquee");
      } else if (mode === ActionMode.Lasso) {
        this.rs.setSelectionToolShape("lasso");
      }
    }
  }

  @HostListener("window:keyup", ["$event"])
  onKeyUp(event: KeyboardEvent): void {
    if ((event.key === "Control" || event.key === "Meta")) {
      this._isCtrlOrMetaPressed = false;
    }
  }

  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === "Control" || event.key === "Meta") {
      this._isCtrlOrMetaPressed = true;
    } else if ((event.ctrlKey || event.metaKey)) {
      if (event.key === "z") { // undo
        event.preventDefault();
        this.ns.showNotification(NotificationType.Error, "undo is still in the making 🥲");
      } else if (event.key === "v") { // redo
        void this.onPaste(event);
      }
    }
  }

  @HostListener("window:blur")
  onUnload(): void {
    this._isCtrlOrMetaPressed = false;
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification(event: BeforeUnloadEvent): void {
    if (this.gs.isModified() && this.getPermission() === Permission.Writable) {
      event.returnValue = "You have unsaved changes! Do you really want to leave?";
    }
  }

  @HostListener("window:keydown.F1", ["$event"])
  openDocs(event: KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const selectedNodes = this.gs.getSelectedNodes();

    for (const node of selectedNodes.values()) {
      openDocs(node.getDefinition());
    }
  }

  @HostListener("window:keydown.shift.C", ["$event"])
  async saveToClipboard(event: KeyboardEvent): Promise<void> {
    // cut only if the focus is not on an input or textarea
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const root = this.gs.getRootGraph();

    try {
      await this.gs.copyGraphToClipboard(root);
      this.ns.showNotification(NotificationType.Success, "Graph copied to clipboard");
    } catch (error) {
      this.es.handlError(error);
    }
  }

  @HostListener("window:cut", ["$event"])
  async onCut(event: KeyboardEvent): Promise<void> {
    // cut only if the focus is not on an input or textarea
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    if (isEmbedded()) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.getPermission() === Permission.Writable) {

      const current = this.gs.getCurrentGraph();
      if (!current) {
        throw new Error("no graph");
      }

      const selectedNodes = this.gs.getSelectedNodes();
      for (const node of selectedNodes.values()) {
        if (node.getDefinition().entry) {
          if (selectedNodes.size === 1) {
            this.ns.showNotification(NotificationType.Error, "cannot remove entry node");
            return;
          } else {
            selectedNodes.delete(node.id);
          }
        }
      }

      const nodeIds = new Set(selectedNodes.keys());

      try {
        await this.gs.copyToClipboard(nodeIds);
        const cut = await this.gs.deleteNodes(nodeIds);
        this.ns.showNotification(NotificationType.Success, nodeIds.size > 1 ? `${cut} nodes cut to clipboard` : `${cut} node cut to clipboard`);
      } catch (error) {
        this.es.handlError(error);
      }
    }
  }

  @HostListener("window:keydown.meta.shift.G", ["$event"])
  async onNew(event: Event, graphType: "standard" | "web" | "github" = "standard"): Promise<void> {
    // cut only if the focus is not on an input or textarea
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    if (isEmbedded() || this.gs.isLoading()) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const permission = this.getPermission();
    if (permission === Permission.Writable || permission === Permission.Unknown) {

      event.preventDefault();
      event.stopPropagation();

      if (graphType === "github") {
        this.ns.showNotification(NotificationType.Info, "For GitHub Action workflows, visit the VS Code Marketplace.", {
          actionButton: "More Info",
          timeout: 10000,
          actionOnClick: () => {
            window.open(`${environment.publicWebsiteUrl}/github`, "_blank");
          },
        });
        return;
      }

      const filename = `untitled${this._untitledCnt === 0 ? "" : ` ${this._untitledCnt}`}.act`;
      this._untitledCnt++;

      if (this.gs.isModified()) {
        const save = confirm("Save changes before creating a new graph?");
        if (!save) {
          return;
        }

        await this.onSave(event);
      }

      this.logger.clearLog();

      await this.gs.openGraph(null, filename, emptyGraph, null, Permission.Writable);
      this.ns.showNotification(NotificationType.Success, "new graph created");

      this.fireConfetti("node-start");

      this.cdr.detectChanges();
    } else {
      this.ns.showNotification(NotificationType.Error, "graph is read-only");
    }
  }

  @HostListener("window:keydown.control.S", ["$event"])
  @HostListener("window:keydown.meta.S", ["$event"])
  async onSave(event: Event): Promise<void> {
    // always prevent default to avoid browser save dialog
    event.preventDefault();
    event.stopPropagation();

    await this.onSaveGraph(event, false);
  }

  @HostListener("window:keydown.control.shift.S", ["$event"])
  @HostListener("window:keydown.meta.shift.S", ["$event"])
  async onSaveAs(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    await this.onSaveGraph(event, true);
  }

  @HostListener("window:keydown.control.W", ["$event"])
  @HostListener("window:keydown.meta.W", ["$event"])
  async onToggleNodeMenu(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    this.expandNodeLibrary = !this.expandNodeLibrary;
  }

  @HostListener(`window:keydown.control.${Shortcut.FitToWindow}`, ["$event"])
  @HostListener(`window:keydown.meta.${Shortcut.FitToWindow}`, ["$event"])
  async onFitToWindow(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    await this.gs.fitGraphToWindow();
    this.ns.showNotification(NotificationType.Success, "canvas view adjusted");
  }

  @HostListener(`window:keydown.control.${Shortcut.Arrange}`, ["$event"])
  @HostListener(`window:keydown.meta.${Shortcut.Arrange}`, ["$event"])
  async onArrangeNodes(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    await this.gs.arrangeNodes();
    await this.gs.fitGraphToWindow();

    this.ns.showNotification(NotificationType.Success, "nodes arranged");
  }

  @HostListener("window:keydown.control.B", ["$event"])
  @HostListener("window:keydown.meta.B", ["$event"])
  async zoomToNode(event: KeyboardEvent): Promise<void> {
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    const node: BaseNode | undefined = this.gs.getSelectedNodes().values().next().value;
    if (node) {
      await this.gs.fitNodeToWindow(node);
    }
  }

  @HostListener("window:keydown.control.O", ["$event"])
  @HostListener("window:keydown.meta.O", ["$event"])
  async onOpen(event: KeyboardEvent): Promise<void> {
    // always prevent default to avoid browser open dialog
    event.preventDefault();
    event.stopPropagation();

    if (this.fs.hasFsApi()) {

      if (this.gs.isModified()) {
        const save = confirm("Save changes before opening a new graph?");
        if (!save) {
          return;
        }

        await this.onSave(event);
      }

      await this.onOpenGraph(null);
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  @HostListener("window:copy", ["$event"])
  async onCopy(event: KeyboardEvent): Promise<void> {
    // copy only if the focus is not on an input or textarea
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    } else if (window.getSelection()?.toString()) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const current = this.gs.getCurrentGraph();
    if (!current) {
      throw new Error("no graph");
    }

    const nodeIds = new Set(this.gs.getSelectedNodes().keys());

    try {
      await this.gs.copyToClipboard(nodeIds);
      this.ns.showNotification(NotificationType.Success, nodeIds.size > 1 ? `${nodeIds.size} nodes copied to clipboard` : `${nodeIds.size} node copied to clipboard`);
    } catch (error) {
      this.es.handlError(error);
    }
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event: MouseEvent): void {
    if (event.button === 3) {
      event.preventDefault();
      event.stopPropagation();

      this.goUpGraphHierarchy(event);
    } else if (event.button === 4) {
      event.preventDefault();
      event.stopPropagation();

      this.goDownGraphHierarchy(event);
    }
  }

  // On Linux, the middle mouse button is used for pasting, while here in the editor
  // we use it for panning the graph. The easiest fix is to use the window keydown events
  // via window:keydown instead.
  // @HostListener("window:paste", ["$event"])
  async onPaste(event: KeyboardEvent): Promise<void> {
    // paste only if the focus is not on an input or textarea
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    if (isEmbedded()) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.getPermission() === Permission.Writable) {

      // As of writing this, the clipboard API in Firefox doesn't fully
      // support the clipboard, only in browser extensions.
      if (!navigator.clipboard || !navigator.clipboard.readText) {
        this.ns.showNotification(NotificationType.Error, "your browser doesn't support reading from the clipboard");
        return;
      }

      let text: string;

      try {
        text = await navigator.clipboard.readText();
      } catch (error) {
        this.es.handlError(error);
        return;
      }

      let graph: IGraph

      try {
        const pastedContent: unknown = load(text);
        if (!pastedContent) {
          throw new ActError(ACTERROR.NOTIFICATIONS, "pasted content is empty");
        }

        if (typeof pastedContent !== "object") {
          throw new ActError(ACTERROR.NOTIFICATIONS, "pasted content is not a graph or node");
        } else if (!("nodes" in pastedContent)) {
          throw new ActError(ACTERROR.NOTIFICATIONS, "pasted content is not a graph or node");
        } else if (!Array.isArray(pastedContent.nodes) || pastedContent.nodes.length === 0) {
          throw new ActError(ACTERROR.NOTIFICATIONS, "pasted content is not a graph or node");
        }

        graph = pastedContent as IGraph;
      } catch (error) {
        if (error instanceof YAMLException) {
          throw new ActError(ACTERROR.NOTIFICATIONS, `error in line line ${error.mark.line}. Pasted content is not a valid YAML string.`);
        }
        this.es.handlError(error);
        return;
      }

      try {
        const newNodes = await this.gs.pasteGraph(graph);

        if (newNodes.length > 0) {
          void this.playSound("assets/tab1.mp3");
        }

        this.ns.showNotification(NotificationType.Success, newNodes.length > 1 ? `${newNodes.length} nodes pasted` : `${newNodes.length} node pasted`);
      } catch (error) {
        this.es.handlError(error);
      }

    } else {
      this.ns.showNotification(NotificationType.Error, "graph is read-only");
    }
  }

  @HostListener("window:keydown.backspace", ["$event"])
  @HostListener("window:keydown.delete", ["$event"])
  async onDelete(event: KeyboardEvent): Promise<void> {
    // delete only if the focus is not on an input or textarea
    if (event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.getPermission() === Permission.Writable) {
      try {
        const nodeIds = new Set<string>();
        for (const [id, node] of this.gs.getSelectedNodes()) {
          if (!node.getDefinition().entry) {
            nodeIds.add(id);
          }
        }

        if (nodeIds.size > 0) {
          const deleted = await this.gs.deleteNodes(nodeIds);
          this.ns.showNotification(NotificationType.Success, deleted > 1 ? `${deleted} nodes deleted` : `${deleted} node deleted`);
        }
      } catch (error) {
        this.es.handlError(error);
      }
    }
  }

  @HostListener("window:keydown.shift.ArrowUp", ["$event"])
  goUpGraphHierarchy(event: KeyboardEvent | MouseEvent): void {
    if (event instanceof KeyboardEvent && event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    void this.gs.goUpGraphHierarchy();
  }

  @HostListener("window:keydown.shift.ArrowDown", ["$event"])
  goDownGraphHierarchy(event: KeyboardEvent | MouseEvent): void {
    if (event instanceof KeyboardEvent && event.target && htmlIsUserInputField(event.target)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    void this.gs.goDownGraphHierarchy();
  }

  @HostListener("window:keydown.control.`", ["$event"])
  onTogglePanel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.expandConsole = !this.expandConsole;
  }

  @HostBinding("class.group-node-opened")
  get groupNodeOpened(): boolean {
    return this.gs.groupNodeOpened();
  }

  @HostListener("window:resize")
  onResize(): void {
    if (!autoFit() || this._autoFitting) {
      return;
    }

    this._autoFitting = true;
    void this.gs.fitGraphToWindow()
      .finally(() => {
        this._autoFitting = false;
      });
  }

  fireConfetti(nodeDivElementId: string): void {
    const nodeStart = document.querySelector(nodeDivElementId) as HTMLElement | null;
    const triangle = confetti.shapeFromPath({ path: "M0 10 L5 0 L10 10z" });
    void confetti({
      shapes: [triangle],
      origin: nodeStart ? {
        x: (nodeStart.getBoundingClientRect().x + (nodeStart.getBoundingClientRect().width / 2)) / window.innerWidth,
        y: (nodeStart.getBoundingClientRect().y + (nodeStart.getBoundingClientRect().height / 2)) / window.innerHeight,
      } : undefined,
    });
  }

  graphStackNames(): string[] {
    const stack = this.gs.getGraphStack().map((_g: GraphModel, index: number) => {
      return index === 0 ? "" : `Level ${index}`;
    });

    if (this.gs.getCurrentName()) {
      return [this.gs.getCurrentName(), ...stack.slice(1)];
    } else {
      return [];
    }
  }

  ngOnInit(): void {

    const os = this.deviceInformationService.getDeviceInfo().os;
    this._isMac = os.toLowerCase().includes("mac");

    const ctrlKey = _ctrlKey(this._isMac);
    const modifierKey = _modifierKey(this._isMac);

    this.labelSwitchSelectionTool = `Switch Selection (${ctrlKey} + ${Shortcut.SwitchSelectionTool})`;
    this.labelFitToWindow = `Fit Graph to Window (${modifierKey} + ${Shortcut.FitToWindow})`;
    this.labelArrange = `Arrange Nodes (${modifierKey} + ${Shortcut.Arrange})`;
    this.labelSave = `Save Graph (${modifierKey} + S)`;
    this.labelSaveAs = `Save Graph As (${modifierKey} + Shift + S)`;

    this.preloadSound("assets/tab1.mp3");
    this.preloadSound("assets/tab3.mp3");

    void this.registry.loadAllFullNodeTypeDefinitions();

    // Prevent the user from going back in history. This is overriden
    // by mousedown event that goes up the group hierarchy
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", (_event: PopStateEvent) => {
      window.history.pushState(null, document.title, window.location.href);
    });

    this.commandButtonSeries = [
      [
        // New Standard Graph
        {
          icon: tablerFile, tourAnchor: null, tooltip: "New Standard Graph (Shift + N)",
          tooltipBottom: false,
          class: "cmd-new", click: (event: MouseEvent): void => {
            void this.onNew(event, "standard");
          }
        },
        // New GitHub Action Workflow
        {
          icon: simpleGithub, tourAnchor: null, tooltip: "New GitHub Action Worfklow",
          tooltipBottom: false,
          class: "cmd-new-github", click: (event: MouseEvent): void => {
            void this.onNew(event, "github");
          }
        },
      ],
      // Open Graph
      [
        {
          icon: tablerFolderOpen, tourAnchor: null, tooltip: `Open Graph(${_modifierKey(this._isMac)} + O)`,
          tooltipBottom: false,
          class: "cmd-open", click: (event: MouseEvent): void => {
            if (this.fs.hasFsApi()) {
              void this.onOpenGraph(event)
            } else {
              this.fileInput.nativeElement.click();
            }
          }
        },
      ],
      [
        // Save Graph
        {
          icon: ionSaveSharp, tourAnchor: "tour.save-graph", tooltip: this.labelSave,
          tooltipBottom: true,
          class: "cmd-save", click: (event: MouseEvent): void => {
            void this.onSaveGraph(event, false);
          },
        },
        // Save Graph As
        {
          icon: ionSave, tourAnchor: "tour.save-graph-as", tooltip: this.labelSaveAs,
          tooltipBottom: false,
          class: "cmd-save", click: (event: MouseEvent): void => {
            void this.onSaveAs(event);
          },
        },
      ],
      // Copy Graph Link to Clipboard
      [
        {
          icon: featherShare2, tourAnchor: null, tooltip: "Copy Graph Link to Clipboard",
          tooltipBottom: true,
          class: "cmd-share custom-button-secondary", click: (event: MouseEvent): void => {
            void this.onShareGraph(event);
          }
        },
        // Copy Graph to Clipboard
        {
          icon: tablerClipboardSmile, tourAnchor: null, tooltip: "Copy Graph to Clipboard (Shift + C)",
          tooltipBottom: true,
          class: "cmd-copy", click: (event: MouseEvent): void => {
            void this.onCopyGraph(event);
          },
        },
      ],
    ];
  }

  async ngAfterViewInit(): Promise<void> {

    const element = document.querySelector("#myElement");
    const markdownContent = `
      **Bold Text**  
      *Italic Text*  
      [Link](https://example.com)
    `;
    initializeMarkdownTooltip([element as HTMLElement], markdownContent);

    this.gs.createEditor(this.rete.nativeElement);

    const connection = this.rs.getConnection();
    connection.addPipe((context) => {
      const { type } = context as { type: string };
      switch (type) {
        case "connectiondrop": {
          const { data } = context as unknown as { type: string, data: ConnectionDrop };
          if (data.created) {
            void this.playSound("assets/tab3.mp3");
          }
        }
      }
      return context;
    });

    const editor = this.rs.getEditor();
    editor.addPipe((context) => {
      const { type } = context as { type: string };
      switch (type) {
        case "connectioncreated": {
          const { data } = context as { data: BaseConnection<BaseNode, BaseNode> | { id: string, isInvalid: boolean, isLoop: boolean, source: string, sourceOutput: string, target: string, targetInput: string } };
          // In case the user pressed Ctrl/Meta while
          // loading the graph don't apply the loop to the connection
          if (this._isCtrlOrMetaPressed && !this.gs.isLoading()) {
            const current = this.gs.getCurrentGraph();
            if (current) {
              let conn = current.executions.get(connectionId(data));
              if (!conn) {
                conn = current.connections.get(connectionId(data));
              }
              if (conn) {
                // update the connection model and the connection object in rete
                data.isLoop = true;
                conn.isLoop = true;
              }
            }
          }
        }
      }
      return context;
    });


    const doubleClickHandler = new DoubleClickHandler({
      doubleClickThreshold: 250,
      onDoubleClick: (): void => {
        void this.gs.goDownGraphHierarchy();
      }
    });

    const plugin = this.rs.getPlugin();
    plugin.addPipe((context) => {
      const { type } = context as { type: string };
      switch (type) {
        case "connectionpath": {
          const { data } = context as unknown as { data: { path: string, points: [Position, Position], payload: { isLoop: boolean, isPseudo: boolean, source: string; target: string } } };

          if (data.payload.isPseudo) {
            data.payload.isLoop = this._isCtrlOrMetaPressed || (data.payload.source != "" && data.payload.source == data.payload.target);
          } else {
            data.payload.isLoop ||= (data.payload.source != "" && data.payload.source == data.payload.target);
          }

          if (!data.payload.isLoop) {
            // if a loop, use the default connection path provided by rete
            // otherwise use our ones that is for non-loopy connections a
            // little bit more curvy
            data.path = classicConnectionPath(data.points, 0.3);
          }
        }
      }
      return context;
    })

    // Scale and transform background grid
    const area = this.rs.getArea();
    area.addPipe((context) => {
      const { type } = context as { type: string };
      switch (type) {
        case "pointerdown": { // click on the background, all nodes deselected
          this.gs.droppedOnGroupNode(null);
          this.scs.nodeSelected([]);
          break;
        }
        case "nodepicked": {
          const { data } = context as { data: { id: string } };
          this.gs.droppedOnGroupNode(data.id);
          const node = this.rs.getEditor().getNode(data.id);
          if (node) {
            this.scs.nodeSelected([node]);
          }

          doubleClickHandler.handleSingleClick();
          break;
        }
      }
      return context;
    })

    try {
      this.logger.clearLog();

      if (location.pathname !== "/") {

        if (location.pathname === "/graph" && location.hash !== "") {
          const hash = location.hash.substring(1);
          const graph = atob(hash);
          await this.gs.openGraph(null, "default", graph, null, getOverridenPermission() ?? Permission.Writable);
        } else if (location.pathname.startsWith("/node/")) {

          const nodeTypeId = location.pathname.split("/")[2];
          if (!nodeTypeId) {
            this.es.handlError(new ActError(ACTERROR.REPORT | ACTERROR.USER_CONSOLE, new Error("missing node type id in url")));
            return;
          }

          const node: INode = {
            id: createUniqueNodeId(nodeTypeId, []),
            type: nodeTypeId,
            position: { x: 0, y: 0 },
            inputs: {},
            outputs: {},
            settings: {
              folded: false,
            },
          };

          const graph: IGraph = {
            editor: undefined, // for single nodes we don't need the editor section at the moment
            entry: "",
            type: GRAPH_TYPE_GENERIC,
            nodes: [node],
            connections: [],
            executions: [],
          }

          if (isDocs()) {
            /* This is a special case, only for the the documentation.
               Only for group ports, add the initial group inputs.
               This is helpful so the user sees what the default node looks like. */
            await this.registry.loadFullNodeTypeDefinitions(new Set([nodeTypeId]));

            const nodeDef = this.registry.getFullNodeTypeDefinitions(nodeTypeId);
            if (nodeDef) {
              node.inputs = node.inputs || {};
              for (const [inputId, input] of Object.entries(nodeDef.inputs)) {
                if (input.array && input.array_port_count) {
                  for (let i = 0; i < input.array_port_count; i++) {
                    node.inputs[`${inputId}[${i}]`] = input.default || null;
                  }
                }
              }
              node.outputs = node.outputs || {};
              for (const [outputId, output] of Object.entries(nodeDef.outputs)) {
                if (output.array && output.array_port_count) {
                  for (let i = 0; i < output.array_port_count; i++) {
                    node.outputs[`${outputId}[${i}]`] = null;
                  }
                }
              }
            }
          }

          await this.gs.openGraph(null, location.pathname, dump(graph), null, getOverridenPermission() ?? Permission.Writable);

        } else if (location.pathname.startsWith("/github/")) {

          const re = /github\/(?<owner>[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38})\/(?<repo>[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38})\/(?<ref>.+)\/(?<path>.github\/.+\.yml)/;

          const components: RegExpExecArray | null = re.exec(location.pathname);
          if (!components) {
            throw new Error("invalid url format");
          }

          const { owner, repo, ref, path } = components.groups as { [key: string]: string };

          if (!owner || !repo || !ref || !path) {
            throw new Error("invalid url");
          }

          const graph: IGraph = await this.gw.graphRead({
            provider: "github", owner, repo, ref, path,
          });

          await this.gs.openGraph(null, location.pathname, dump(graph), null, getOverridenPermission() ?? Permission.Writable);

        } else if (location.pathname.startsWith("/shared/")) {
          const source = location.pathname.replace("/shared/", "");

          const graph: IGraph = await this.gw.groupNodeRead(source)
          await this.gs.openGraph(null, location.pathname, dump(graph), null, getOverridenPermission() ?? Permission.ReadOnly);
        }
      } else {
        await this.gs.openGraph(null, "untitled.act", emptyGraph, null, getOverridenPermission() ?? Permission.Writable);
      }

      this.cdr.detectChanges();
    } catch (error) {
      this.es.handlError(error);
    }

    if (this.deviceInformationService.isDesktop() && !this.fs.hasFsApi()) {
      // In the docs, and on e.g. the website, don't show the browser warning.
      if (!isDocs() && !isEmbedded()) {
        this.logger.addLog(LogLevel.Error, "Sorry, your browser doesn't support the latest web features. For the best experience, please switch to Google Chrome, Microsoft Edge, or Opera.");
      }
    }

    if (this.deviceInformationService.isDesktop() && this.getPermission() === Permission.Writable && !isEmbedded() && localStorage.getItem("tour") !== "finished") {
      let endSub: Subscription | null = null;
      let stepSub: Subscription | null = null;
      let stepHide: Subscription | null = null;
      this.tourService.initialize([
        {
          anchorId: "tour.start",
          title: "🚀 Welcome to Actionforge 🎉",
          content: `<div class="animate-fade flex flex-col justify-center items-center gap-y-4">
            </div>`,
          placement: {
            horizontal: true,
          },
          showArrow: false,
          // Hide tour dialog during initialization,
          // as long as it is not centered correctly.
          // Once the dialog is centered in start$
          // the hidden class is removed.
          popoverClass: "hidden",
        },
        {
          anchorId: "tour.nodemenu",
          title: "🧰 Node Menu",
          content: "This is your node menu, with<br/>a collection of different nodes.",
          placement: {
            horizontal: true,
          },
        },
        {
          anchorId: "tour.save-graph",
          title: "💾 Save Graph",
          content: "Once you have built your graph,<br/>you can save it locally.",
          placement: {
            horizontal: true,
          },
        },
        {
          anchorId: "tour.actrun-download",
          title: "⬇️ Download Runtime",
          content: `
          <div class="flex flex-col max-w-96">
            
            <p class="w-full text-left">
              Download
              <span class='bg-gray-100 text-gray-800 mx-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300'>actrun</span>
              to execute your graph locally. To run it use the following CLI command:
            </p>

            <div class="flex flex-row w-full h-full bg-gray-100 text-gray-800 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300 font-mono select-text">
              $ actrun my-graph.act
            </div>

          </div>
          `,
          placement: {
            horizontal: false,
            yPosition: "above",
          },
        },
        {
          anchorId: "tour.docs",
          title: "📖 Documentation",
          content: "For more info check out the documentation.",
          endBtnTitle: "Enjoy! 🙂",
          placement: {
            horizontal: true,
            xPosition: "after",
          },
        }
      ]);

      this.tourService.start$.subscribe(() => {
        setTimeout(() => {
          const mainSection = document.querySelector("#mat-menu-panel-0") as HTMLElement | null;
          if (mainSection) {
            // manually center the tour dialog since its top left corner points to a
            // hidden div container that is located in the center of the page.
            mainSection.style.transform = "translate(-50%, -50%)";
            mainSection.style.position = "absolute";

            // remove hidden that is set during initialize()
            mainSection.classList.remove("hidden");
          }

          // Manually dispatch resize event to trigger a resize observer in ngx-ui-tour.
          // In particular 'cdk-overlay-connected-position-bounding-box' needs to be updated
          // which somehow influences the position of the tour dialog. The resize observer is here:
          // https://github.com/hakimio/ngx-ui-tour/blob/26b33eda65e3328a155618779748ac689565ee8f/projects/ngx-ui-tour-core/src/lib/tour-backdrop.service.ts#L132
          window.dispatchEvent(new Event("resize"));
        }, 1000);
      });

      endSub = this.tourService.end$.subscribe(() => {
        localStorage.setItem("tour", "finished");

        endSub!.unsubscribe();
        endSub = null;
        stepSub!.unsubscribe();
        stepSub = null;
        stepHide!.unsubscribe();
        stepHide = null;

        this.fireConfetti("node-start");
      });

      stepSub = this.tourService.stepShow$.subscribe((step) => {
        document.querySelector(`[tourAnchor = "${step.step.anchorId}"]`)?.classList.add("animate-pulse-extrem");
      });

      stepHide = this.tourService.stepHide$.subscribe((step) => {
        document.querySelector(`[tourAnchor = "${step.step.anchorId}"]`)?.classList.remove("animate-pulse-extrem");
      });

      this.tourService.setDefaults({
        closeOnOutsideClick: false,
        disablePageScrolling: true,
        duplicateAnchorHandling: "registerFirst"
      });

      if (!isEmbedded()) {
        this.tourService.start();
      }
    }
  }

  async onLoadGraph(event: Event): Promise<void> {

    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file: File = input.files[0];
      const fileReader = new FileReader();

      fileReader.onload = (): void => {
        this.logger.clearLog();

        void this.gs.openGraph(file, "default", fileReader.result as string, null, Permission.Writable);
      };

      fileReader.readAsText(file);
    }
  }

  async onOpenGraph(event: Event | null): Promise<void> {
    let file: File | null = null;

    if (event) {
      event.preventDefault();
      event.stopPropagation();

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        file = input.files[0];
      }
    }

    await this.openGraphFromFile(file);
  }

  async onDrop(files: (File | FileSystemFileHandle)[]): NoThrowPromise<void> {
    if (files.length !== 1) {
      this.es.handlError(new ActError(ACTERROR.NOTIFICATIONS, new Error("only one file can be dropped")));
      return;
    }

    await this.openGraphFromFile(files[0]);
  }

  async openGraphFromFile(file: File | FileSystemFileHandle | null): NoThrowPromise<void> {
    if (isEmbedded() || this.gs.isLoading()) {
      return;
    }

    try {
      this.logger.clearLog();

      const { name, data, file: file2 } = await this.fs.readGraphFromFile(file);
      await this.gs.openGraph(file2, name, data, null, Permission.Writable);

      this.ns.showNotification(NotificationType.Success, "graph opened");
    } catch (error) {
      this.es.handlError(error);
    }
  }

  async onShareGraph(event: MouseEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    const root = this.gs.getRootGraph();
    const targetUrl = await this.gs.shareNodes(!(event.metaKey || event.ctrlKey), root, [...root.nodes.keys()]);
    if (targetUrl) {
      try {
        await navigator.clipboard.writeText(targetUrl);
        this.ns.showNotification(NotificationType.Success, "graph url copied to clipboard");
      } catch (error) {
        this.ns.showNotification(NotificationType.Error, getErrorMessage(error));
      }
    }
  }

  async onSaveGraph(event: Event | null, saveAs: boolean): Promise<void> {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (isEmbedded()) {
      return;
    }

    try {
      const root = this.gs.getRootGraph();
      const serialized = this.gs.serializeGraph(root);
      const info: WriteInfo = await this.fs.writeFile(this.gs.fileHandle, serialized, {
        saveAs,
        suggestedName: this.gs.getCurrentName(),
      });

      this.gs.setModified(false);

      if (info.fhandle) {
        this.gs.setFileHandle(info.fhandle);
        this.ns.showNotification(NotificationType.Success, `graph saved to "${info.fhandle.name}"`);
      } else {
        this.ns.showNotification(NotificationType.Success, "graph downloaded");
      }
    } catch (error) {
      this.es.handlError(error);
    }
  }

  async onCreateNode(nodeTypeId: string): Promise<void> {

    const current = this.gs.getCurrentGraph();
    if (!current) {
      throw new Error("no graph");
    }

    const nodeId = createUniqueNodeId(nodeTypeId, current.nodes.keys());
    const isGroupNode = nodeTypeId.startsWith("group@");

    let nodeDef = this.registry.getFullNodeTypeDefinitions(nodeTypeId);
    if (!nodeDef) {
      await this.registry.loadFullNodeTypeDefinitions(new Set([nodeTypeId, ...(isGroupNode ? ["group-inputs@v1", "group-outputs@v1"] : [])]));
      nodeDef = this.registry.getFullNodeTypeDefinitions(nodeTypeId);
      if (!nodeDef) {
        throw new Error(`failed to load node type definition for ${nodeTypeId}`);
      }
    }

    const nodeModel: NodeModel = {
      id: nodeId,
      type: nodeTypeId,
      def: nodeDef,
      position: { x: 100, y: 100 },
      inputs: new Map<string, IInput>(),
      outputs: new Map<string, IOutput>(),
      graph: isGroupNode ? this.createGroupGraphModel() : undefined,
    }

    const node: BaseNode = await this.gs.createAndAddNode(nodeModel, true);

    void this.playSound("assets/tab1.mp3");

    // center node on screen
    const area = this.rs.getArea();
    const [hw, hh] = [this.rete.nativeElement.clientWidth / 2, this.rete.nativeElement.clientHeight / 2];
    const { x, y, k } = area.area.transform;
    const cycle = 50; // Below nodes are offset always by 10, and then it goes back to 0 after x times
    const newX = (hw - x) / k - (node.width / 2) + (this._nodePlacementOffset * 10 % cycle);
    const newY = ((hh - y) / k - (node.height / 2) + (this._nodePlacementOffset * 10 % cycle));
    await area.translate(node.id, { x: newX, y: newY });
    this._nodePlacementOffset++;

    await this.rs.selectNodes([node.id]);

    const nodeElement = document.querySelector(`node-${node.id}`);
    if (nodeElement) {
      nodeElement.classList.add("animate-glow-and-fade");
      setTimeout(() => {
        nodeElement.classList.remove("animate-glow-and-fade");
      }, 250);
    }
  }

  async onCopyGraph(_event: MouseEvent): Promise<void> {
    const current = this.gs.getCurrentGraph();
    if (!current) {
      throw new Error("no graph");
    }

    const nodeIds = new Set(current.nodes.keys());

    try {
      await this.gs.copyToClipboard(nodeIds);
      this.ns.showNotification(NotificationType.Success, nodeIds.size > 1 ? `${nodeIds.size} nodes copied to clipboard` : `${nodeIds.size} node copied to clipboard`);
    } catch (error) {
      this.es.handlError(error);
    }
  }

  getPermission(): Permission {
    return this.gs.getPermission();
  }

  async playSound(audiopath: string): Promise<void> {
    try {
      const audioElement = new Audio(audiopath);
      await audioElement.play();
    } catch {
      // ignore, sound is not critical
    }
  }

  preloadSound(audiopath: string): void {
    const audioElement = new Audio(audiopath);
    audioElement.load();
  }

  createGroupGraphModel = (): GraphModel => {

    const createGroupInputModel = (subNodeTypeId: string, positionX: number): { nodeId: string, model: NodeModel } => {
      const groupInputDef = this.registry.getFullNodeTypeDefinitions(subNodeTypeId);
      if (!groupInputDef) {
        throw new Error(`failed to load node type definition for ${subNodeTypeId} `);
      }

      const sanitizedNodeId = subNodeTypeId.split(/[^a-zA-Z0-9-]/).join("-").replace(/^github.com-/, "gh-");
      const nodeId = `${sanitizedNodeId}-${generateRandomWord(3)}`;

      return {
        nodeId, model: {
          id: nodeId,
          type: subNodeTypeId,
          def: groupInputDef,
          position: { x: positionX, y: 100 },
          inputs: new Map<string, IInput>(),
          outputs: new Map<string, IOutput>(),
        }
      }
    }

    const start = createGroupInputModel("group-inputs@v1", 0);
    const output = createGroupInputModel("group-outputs@v1", 400);

    return {
      editor: undefined, // i don't think group nodes need editor information at the moment
      type: GRAPH_TYPE_GROUP,
      entry: start.nodeId,
      connections: new Map(),
      nodes: new Map([
        [start.nodeId, start.model],
        [output.nodeId, output.model],
      ]),
      executions: new Map(),
      info: undefined,
      inputs: undefined,
      outputs: undefined,
    }
  }

  commandButtonSeries: Command[][] = []
}

const emptyGraph = `editor:
  version:
    created: ${GRAPH_VERSION}
entry: start
type: 'generic'
nodes:
  - id: start
    type: start@v1
    position:
      x: 0
      y: 0
connections: []
executions: []
`;

function initializeMarkdownTooltip(element: HTMLElement[], markdownContent: string): void {
  const converter = new Showdown.Converter();
  const htmlContent = converter.makeHtml(markdownContent);

  tippy(element, {
    content: htmlContent,
    allowHTML: true,
    theme: "light",
  });
}